









































































import {Component, Mixins, Vue, Watch} from "vue-property-decorator"
import Product from "@/models/Product";
import ProductService from "@/services/ProductService";
import {getModule} from "vuex-module-decorators";
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";
import VideoProductComponent from "@/components/products/VideoProductComponent.vue";
import ProductCategory from "@/models/ProductCategory";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import PaginationMixin from "@/mixins/PaginationMixin";

@Component({ components: { VideoProductComponent } })
export default class VideoView extends Mixins(PaginationMixin) {

	categoryTreeviewModule = getModule(CategoryTreeviewModule)
	get productCategories() {return this.categoryTreeviewModule.productCategories}
	get selectedCategories() {return this.categoryTreeviewModule.selectedCategories}
	set selectedCategories(categoryIds: number[]) {this.categoryTreeviewModule.setSelectedCategories(categoryIds)}
	get productQueryId() { return this.$route.params.id }
	featuredCategory: ProductCategory | null = null
	product: Product = new Product()
	loading: boolean = false
	sortDesc = false
	products2: MultipleItem<Product> = { items: [], totalItems: 0 }
	showPlayer: boolean = false

	created() {
		this.selectedCategories.splice(0, this.selectedCategories.length)
		this.refresh()
	}

	// TODO: Reformat this

	async refresh() {
		this.showPlayer = false

		await Handler.getItems(this, this.products2, () =>
			ProductService.getPublicProducts(this.page - 1, this.size, this.search, this.selectedCategories, true)
		)

		this.setPageCount(this.products2.totalItems!!)

		if (this.products2.items.length != 0) { this.showPlayer = true }
	}

	setVideo(product: Product) {
		this.product = product
		// @ts-ignore
		this.$refs.lazyVideo.resetView()
		window.scrollTo({
			top: 100,
			left: 0,
			behavior: 'smooth'
		});
	}

	treeToList(tree: ProductCategory) {
		const result = [];
		result.push(tree);
		const children = tree.children || [];
		children.forEach(child => {
			const childResult = this.treeToList(child);
			result.push(...childResult)
		})

		return result;
	}

	@Watch("featuredCategory")
	onFeaturedCategoryChanged() {
		if (this.featuredCategory != null) {
			let ids: number[] = []
			this.treeToList(this.featuredCategory).forEach(cat => ids.push(cat.id!))
			this.selectedCategories = ids
		} else {
			this.selectedCategories.splice(0, this.selectedCategories.length)
		}

		this.refresh()
	}
}
